/**
 * Enum for modal types.
 * @readonly
 * @enum type of modal
 */
const MODAL_TYPES = Object.freeze({
  FULL_SCREEN: 'full_screen',
  GREEN_COAST_SIDE_RIGHT_CENTERED: 'green-coast-side-right-centered',
  GREEN_COAST_SIDE_RIGHT_START: 'green-coast-side-right-start',
  BOTTOM_ADJUST_TO_CONTENT: 'bottom-adjust-to-content',
  GREEN_SCREEN_CENTERED: 'green-screen-centered',
  GREEN_SCREEN_CENTERED_FIT: 'green-screen-centered green-screen-centered--fit',
  GREEN_SCREEN_CENTERED_BIG: 'green-screen-centered green-screen-centered--big',
  MODAL_SCREEN_CHECKOUT: 'modal-screen-checkout modal-screen-checkout--fit',
  BUY_LOOK: 'buy-look__modal',
  HALF_SCREEN_RIGHT_WITH_HEADER: 'half-screen__right__with__header',
  CART_LIST_CHECKOUT: 'green-coast-side-right-start',
  SCREEN_CENTERED: 'screen-centered',
  SCREEN_CENTERED_BIG: 'screen-centered screen-centered--big',
  SCREEN_CENTERED_NEWS_DESIGNERS: 'screen-centered screen-centered--news-designers',
  CONFIRMATION_MODAL: 'confirmation-modal',
  VUESTORE_SIDE_RIGHT_START: 'vuestore-side-right-start',
  VUESTORE_SIDE_RIGHT_START_FULL_HEIGHT: 'vuestore-side-right-start full-height',
  VUESTORE_GRID_SIDE: 'vuestore-grid-side',
  VUESTORE_SIDE_BOTTOM_START: 'vuestore-side-bottom-start',
  CHATBOX_SIDE_RIGHT_START: 'chatbox-side-right-start',
  CHATBOX_SIDE_RIGHT: 'chatbox-side-right',
});

export default MODAL_TYPES;
