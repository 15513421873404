import { mapGetters } from 'vuex';
const DETECTOR = {
  MOBILE: /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/,
  TABLET: /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i,
};
const CSS_DESKTOP = '(min-width: 62.25em)'; //62.25 * 16 =     996px

const TYPES = {
  DESKTOP: 'DESKTOP',
  TABLET: 'TABLET',
  MOBILE: 'MOBILE',
};

const MEDIA_QUERIES = {
  DESKTOP: {
    NORMAL: '(min-width: 995px)',
  },
  MOBILE: {
    SMALL: '(max-width:479px)',
    NORMAL: '(max-width:768px)',
  },
  TABLET: {
    PORTRAIT: '(min-width: 480px) and (max-width:767px)',
    LANDSCAPE: '(min-width: 767px) and (max-width:1366px)',
  },
  OTHER: {
    BIG: '(min-width:1280px)',
  },
};

const navigatorDetect = {
  data() {
    return {
      isClientMounted: false,
      currentDevice: null,
      desktopMediaQueryList: undefined,
    };
  },
  methods: {
    matchHandler({ matches }) {
      if (matches) this.currentDevice = TYPES.DESKTOP;
      else this.currentDevice = TYPES.MOBILE;
    },
    getDeviceType() {
      const UA = this.isServer ? this.userAgent : window.navigator.userAgent;
      if (DETECTOR.TABLET.test(UA)) return TYPES.TABLET;
      if (DETECTOR.MOBILE.test(UA)) return TYPES.MOBILE;
      return TYPES.DESKTOP;
    },
    hasMediaQuery(mq) {
      const screen = false; // We should be accepting the header Accept-CH: Viewport-Width to have Viewport-Width
      return this.isServer ? screen : window.matchMedia(mq).matches;
    },
    isMobileScreen() {
      return this.hasMediaQuery(MEDIA_QUERIES.MOBILE.SMALL);
    },
    isResponsiveModeScreen() {
      return this.hasMediaQuery(MEDIA_QUERIES.MOBILE.NORMAL);
    },
    isPortraitTabletScreen() {
      return this.hasMediaQuery(MEDIA_QUERIES.TABLET.PORTRAIT);
    },
    isLandscapeTabletScreen() {
      return this.hasMediaQuery(MEDIA_QUERIES.TABLET.LANDSCAPE);
    },
    isBigScreenScreen() {
      return this.hasMediaQuery(MEDIA_QUERIES.OTHER.BIG);
    },
    isDesktopScreen() {
      return (
        !this.isResponsiveModeScreen() &&
        !this.isPortraitTabletScreen() &&
        !this.isLandscapeTabletScreen() &&
        !this.isBigScreenScreen()
      );
    },
    isCssDesktopScreen() {
      return this.hasMediaQuery(CSS_DESKTOP);
    },
  },
  computed: {
    ...mapGetters('page', {
      userAgent: 'getUserAgent',
      isServer: 'isServer',
    }),
    ...mapGetters('akamai', {
      isDesktopAkamai: 'isDesktopDevice',
      isTabletAkamai: 'isTabletDevice',
      isMobileAkamai: 'isMobileDevice',
    }),
    isDesktopDevice() {
      const deviceTypeDesktop = this.getDeviceType() === TYPES.DESKTOP;
      if (!this.currentDevice) return deviceTypeDesktop;
      return this.currentDevice === TYPES.DESKTOP;
    },
    isTabletDevice() {
      const deviceTypeTablet = this.getDeviceType() === TYPES.TABLET;
      return deviceTypeTablet;
    },
    isMobileDevice() {
      const deviceTypeMobile = this.getDeviceType() === TYPES.MOBILE;
      if (!this.currentDevice) return deviceTypeMobile;
      return this.currentDevice === TYPES.MOBILE;
    },
    isDesktopDeviceByHeaderAndResolution() {
      return this.isDesktopAkamai !== undefined && !this.isClientMounted ? this.isDesktopAkamai : this.isDesktopDevice;
    },
    isTabletDeviceByHeaderAndResolution() {
      return this.isTabletAkamai !== undefined && !this.isClientMounted ? this.isTabletAkamai : this.isTabletDevice;
    },
    isMobileDeviceByHeaderAndResolution() {
      return this.isMobileAkamai !== undefined && !this.isClientMounted ? this.isMobileAkamai : this.isMobileDevice;
    },
    isChrome() {
      const UA = this.isServer ? this.userAgent : window.navigator.userAgent;
      return /Chrome/i.test(UA);
    },
    isSafari() {
      const UA = this.isServer ? this.userAgent : window.navigator.userAgent;
      return /Safari/i.test(UA);
    },
    isIOS() {
      const UA = this.isServer ? this.userAgent : window.navigator.userAgent;

      return /iPad|iPhone|iPod/.test(UA) && !window.MSStream;
    },
    isAndroid() {
      const UA = this.isServer ? this.userAgent : window.navigator.userAgent;

      return /android/i.test(UA);
    },
    isMacOS() {
      const UA = this.isServer ? this.userAgent : window.navigator.userAgent;

      return /Macintosh/.test(UA) && !/iPad|iPhone|iPod/.test(UA);
    },
  },
  mounted() {
    this.desktopMediaQueryList = window.matchMedia(MEDIA_QUERIES.DESKTOP.NORMAL);
    this.matchHandler(this.desktopMediaQueryList);
    if ('addEventListener' in this.desktopMediaQueryList) {
      this.desktopMediaQueryList.addEventListener('change', this.matchHandler);
    } else {
      this.desktopMediaQueryList.addListener(this.matchHandler);
    }
    this.isClientMounted = true;
  },
  unmounted() {
    this.isClientMounted = false;
    if (!this.desktopMediaQueryList) return;

    if ('removeEventListener' in this.desktopMediaQueryList) {
      this.desktopMediaQueryList.removeEventListener('change', this.matchHandler);
    } else {
      this.desktopMediaQueryList.removeListener(this.matchHandler);
    }
  },
};

export default navigatorDetect;
